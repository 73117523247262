import React, {
   useState,
   useContext,
   useEffect,
   Fragment,
   useCallback,
   useMemo
} from "react";
import { db, actions } from "../../store";
import { firebaseDb } from "../../api/firebase";
import "./ShekinahGame.css";
import ShowPregunta from "./ShowPregunta";
import GameStats from "./GameStats";
import Respuesta from "./Respuesta";
import ShowStatus from "./ShowStatus";
import { useAuth } from "../../hooks";
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TitleCase, accentFold, getVersos } from "../../utils";
import libros from "../Cita/data.json";

const ShekinahGame = ({ bell, buzz }) => {
   const { state, dispatch } = useContext(db);
   const { user } = useAuth();
   const [prevQuestions, setprevQuestions] = useState([]);
   const [Status, setStatus] = useState("");
   const [SelectedAnswer, setSelectedAnswer] = useState("");
   const [Counter, setCounter] = useState(0);
   const [racha, setracha] = useState(0);
   const [hideButton, sethideButton] = useState(true);
   const [Pregunta, setPregunta] = useState(null);
   const [Loaded, setLoaded] = useState(false);
   const [thisQ, setthisQ] = useState({});
   const [boton, setBoton] = useState("Responder");

   useEffect(() => {
      if (
         !user.emailVerified &&
         user.providerData[0].providerId == "password"
      ) {
         dispatch({
            type: actions.SetView,
            payload: { CurrentView: "Profile", PreviousView: "Game" }
         });
      } else if (!Loaded) {
         const totalQuestions = 3195;
         let randomIndex = Math.floor(Math.random() * totalQuestions);

         let game = state.currentGame;
         if (typeof thisQ.points !== "undefined") {
            if (
               game.prevQuestions.filter((q) => q.id === thisQ.id).length === 0
            ) {
               game.prevQuestions.push(thisQ);
               // Add points of current questions to session points

               game.points += thisQ.points;

               if (thisQ.correcta) {
                  game.correctas += 1;
               }

               game.contestadas += 1;

               //Save Previous Questions and game State
               setprevQuestions(game.prevQuestions);
               dispatch({ type: actions.StartGame, newGame: game });

               if (
                  state.currentGame.prevQuestions.length ===
                  state.matchQuestions
               ) {
                  cancelGame();
               }
            }
         }

         while (
            game.prevQuestions.filter((q) => q.id === randomIndex).length > 0
         ) {
            randomIndex = Math.floor(Math.random() * totalQuestions);
         }
         var ref = firebaseDb.ref("Preguntas");
         ref.orderByChild("ID")
            .equalTo(`${randomIndex}`)
            .once("value")
            .then((snapshot) => {
               let q = snapshot.val();
               let question = Object.keys(q).map((k) => q[k])[0];
               question.Respuestas = shuffle(question.Respuestas);

               setStatus("");

               //Grabar Cita Biblica en la pregunta
               let elLibro = libros.data.find(
                  (l) =>
                     accentFold(l.name.toLowerCase()) ===
                     accentFold(question.Libro.toLowerCase())
               );
               console.log (elLibro);
               if (typeof elLibro !== "undefined") {
                  question.query = `${elLibro.id}${question.CAP}:${getVersos(question)}`;
               } else { 
                  question.query = "";
               }   

               setPregunta(question);
               setLoaded(true);
            });
      }
   }, [Loaded, prevQuestions]);

   const selectAnswer = useCallback(
      (e) => {
         if (Status === "") {
            setSelectedAnswer(e.currentTarget.value);
            sethideButton(false);
         }
      },
      [Status]
   );

   const cancelGame = () => {
      //Get Previous points for the user
      const topTenRef = firebaseDb.ref("TopTen");
      topTenRef
         .orderByChild("player")
         .equalTo(`${state.currentGame.player}`)
         .once("value")
         .then((snapshot) => {
            let q = snapshot.val();

            //Si el jugador tiene puntos anteriores, agregarlos al total de esta ronda
            let userPoints =
               q === null ? 0 : Object.keys(q).map((k) => q[k])[0].total;

            let matchs =
               q === null ? 0 : Object.keys(q).map((k) => q[k])[0].Partidas;

            dispatch({
               type: actions.CancelGame,
               cancel: {
                  cancelGame: true,
                  currentUserSavedPoints: userPoints,
                  userMatchs: matchs
               }
            });
         });
   };

   const shuffle = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
         let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

         [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
   };

   const responder = (e) => {
      //
      if (boton === "Responder") {
         let answer = Pregunta.Respuestas.filter(function (item) {
            return item.texto === SelectedAnswer;
         });

         setCounter(Counter + 1);
         sethideButton(true);

         let myQ = { id: +Pregunta.ID, points: 0 };
         let points = 1;
         if (Pregunta.Dificultad === "Avanzado") {
            points = 3;
         } else if (Pregunta.Dificultad === "Medio") {
            points = 2;
         }

         if (answer[0].correcta) {
            playSound("bell");
            setStatus("Correcto");
            let streak = racha + 1;
            myQ.correcta = true;
            myQ.points = points * streak;
            setracha(streak);
         } else {
            playSound("buzz");
            setStatus("Incorrecto");
            myQ.correcta = false;
            myQ.points = 0;
            if (racha > 0) {
               setracha(0);
            }
         }
         setthisQ(myQ);
         setBoton("Continuar");
         sethideButton(false);
      } else {
         setSelectedAnswer("");
         setLoaded(false);
         setBoton("Responder");
         sethideButton(true);
      }
   };

   function setSoundConfig() {
      dispatch({ type: actions.SetSound });
      console.log(state.userSettings.sound);
   }

   useEffect(() => {
      firebaseDb
         .ref("Jugadores")
         .child(`/${state.currentGame.player}/Settings/`)
         .update({ sound: state.userSettings.sound }, (error) => {
            if (error) {
               console.log("Data could not be saved." + error);
            } else {
               console.log("Data saved successfully.");
            }
         });
   }, [state.userSettings.sound]);

   function playSound(soundName) {
      if (state.userSettings.sound) {
         switch (soundName) {
            case "bell":
               bell.play();
               break;
            case "buzz":
               buzz.play();
               break;
            default:
               return;
         }
      }
   }

   return Loaded ? (
      <Fragment>
         <GameStats />
         <ShowPregunta pregunta={Pregunta} />

         <div className="add_body">
            {Pregunta.Respuestas.map((item, key) => (
               <Respuesta
                  index={key}
                  key={key}
                  respuesta={item}
                  answer={SelectedAnswer}
                  status={Status}
                  onClick={selectAnswer}
               />
            ))}

            <ShowStatus status={Status} pregunta={Pregunta} />
         </div>
         <div className="game_footer">
            <button onClick={cancelGame} className="btn btn-game-danger">
               Terminar
            </button>
            <button className="btn btn-game-primary" onClick={setSoundConfig}>
               <FontAwesomeIcon
                  icon={state.userSettings.sound ? "volume-up" : "volume-mute"}
               />
            </button>
            <button
               disabled={hideButton}
               onClick={responder}
               className="btn btn-game-primary"
            >
               {boton}
            </button>
         </div>
      </Fragment>
   ) : (
      <ReactLoading
         type="spinningBubbles"
         color="#0f5ab6"
         height={"20%"}
         width={"20%"}
         className="loading-animation"
      />
   );
};

export default ShekinahGame;
