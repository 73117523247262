const Actions = {
   StartGame: "StartGame",
   SetQuestions: "SetQuestions",
   SetTopTen: "SetTopTen",
   EndGame: "EndGame",
   CancelGame: "CancelGame",
   SetCurrentUser: "SetCurrentUser",
   SignedIn: "SignedIn",
   LogOut: "LogOut",
   SetView: "SetView",
   SetJugador: "SetJugador",
   SetSignedInUser: "SetSignedInUser",
   SetSound: "SetSound",
   SetSettings: "SetSettings"
};

export default Actions;
