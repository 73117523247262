import React, { Fragment, memo } from "react";
import "./ShekinahGame.css";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   faCheckCircle,
   faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
//import Tachyons from 'tachyons/css/tachyons.min.css'

const Respuesta = ({ index, respuesta, answer, status, onClick }) => {
   const bounce = answer === respuesta.texto ? "bounce" : "";

   const estilo = () => {
      let retval = "n"; //normal

      if (answer === respuesta.texto) {
         if (status !== "" && respuesta.correcta) {
            retval = "cs";
         } else if (status === "Incorrecto" && answer === respuesta.texto) {
            retval = "is";
         } else {
            retval = "ns";
         }
      } else {
         if (status !== "" && respuesta.correcta) {
            retval = "c";
         } else if (status === "Incorrecto" && answer === respuesta.texto) {
            retval = "i";
         } else {
            retval = "n";
         }
      }

      return retval;
   };

   const borderClass = () => {
      let estilos = {
         n: "outline-light",
         ns: "outline-light--selected",
         c: "success",
         cs: "success-sel",
         i: "danger",
         is: "danger-sel"
      };
      return estilos[estilo()];
   };

   return (
      <Fragment>
         <Button
            className={`text-left my-1 text-big respuesta ${bounce}`}
            variant={borderClass()}
            value={respuesta.texto}
            onClickCapture={onClick}
         >
            <div className="answer-grid">
               <Badge variant={`b-${borderClass()}`}>{index + 1}</Badge>
               <span className="badge-answer-text">{respuesta.texto} </span>
               <div className="answer-icon">
                  {(estilo() === "c" || estilo() === "cs") && (
                     <FontAwesomeIcon
                        className="answer-ok"
                        icon={faCheckCircle}
                     />
                  )}
                  {(estilo() === "i" || estilo() === "is") && (
                     <FontAwesomeIcon
                        className="answer-wrong"
                        /*  size={50} */
                        icon={faTimesCircle}
                     />
                  )}
               </div>
            </div>
         </Button>
      </Fragment>
   );
};

export default memo(Respuesta);
