import ls from "local-storage";

const StoreReducer = (state, action) => {
   switch (action.type) {
      case "StartGame":
         ls.set("Game", action.newGame);
         ls.set("playing", true);
         return {
            ...state,
            currentGame: action.newGame,
            playing: true,
            CurrentView: "Game"
         };
      case "SetQuestions":
         return {
            ...state,
            questions: action.payload.questions,
            Loading: action.payload.Loading
         };
      case "SetTopTen":
         return {
            ...state,
            leaders: action.payload.leaders,
            Loading: action.payload.Loading
         };
      case "EndGame":
         ls.clear();
         return {
            ...state,
            currentGame: {},
            playing: false,
            Loading: true,
            cancelGame: false,
            CurrentView: "TopTen"
         };
      case "CancelGame":
         return {
            ...state,
            cancelGame: action.cancel.cancelGame,
            currentUserSavedPoints: action.cancel.currentUserSavedPoints,
            userMatchs: action.cancel.userMatchs,
            CurrentView: "EndGame"
         };
      case "LogOut":
         return { ...state, SignedIn: false };
      case "SignedIn":
         return { ...state, SignedIn: true };
      case "SetCurrentUser":
         return { ...state, CurrentUser: action.CurrentUser };
      case "SetView":
         return {
            ...state,
            CurrentView: action.payload.CurrentView,
            PreviousView: action.payload.PreviousView
         };
      case "SetJugador":
         return { ...state, Jugador: action.Jugador };
      case "SetSignedInUser":
         return { ...state, SignedInUser: action.SignedInuser };
      case "SetSound":
         return {
            ...state,
            userSettings: {
               ...state.userSettings,
               sound: !state.userSettings.sound
            }
         };
      case "SetSettings":
         return {
            ...state,
            userSettings: action.payload.settings
         };
      default:
         return {
            ...state
         };
   }
};
export default StoreReducer;
