import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactHtmlParser from "react-html-parser";

const Cita = ({ show, cita, texto, onClose }) => {
   return (
      <>
         <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
               <Modal.Title>{cita}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-big">
               {ReactHtmlParser(texto)}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="game-primary" onClick={onClose}>
                  Cerrar
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
};

export default Cita;
