import React, { useContext, useState } from "react";
import { db, actions } from "../../store";
import "./StartGame.css";
import Top30 from "../TopTen/Top30";
import Share from "./Share.js";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../hooks";

const StartGame = () => {
   const { state, dispatch } = useContext(db);
   const { user } = useAuth();

   const editProfile = () => {
      if (user !== null) {
         dispatch({
            type: actions.SetView,
            payload: { CurrentView: "Profile", PreviousView: "TopTen" }
         });
      }
   };

   const startGame = () => {
      if (user !== null) {
         dispatch({
            type: actions.StartGame,
            newGame: {
               player: user.uid,
               prevQuestions: [],
               points: 0,
               correctas: 0,
               contestadas: 0
            }
         });
      } else {
         dispatch({
            type: actions.SetView,
            payload: {
               CurrentView: "Login",
               PreviousView: "StartGame"
            }
         });
      }
   };

   const [show, setShow] = useState(false);

   const handleShow = (e) => {
      setShow(true);
   };

   const handleClose = (e) => {
      setShow(false);
   };

   return (
      <div className="bottom">
         <Top30 show={show} onClose={handleClose} />
         {/*          <h5>Empezar Juego</h5> */}
         <Button
            variant="game-primary"
            onClick={handleShow}
            className="start__share-btns"
         >
            <span>Top {state.topStart + state.topEnd} </span>
            <FontAwesomeIcon icon="trophy" />
         </Button>
         <Share className="start__share-btns" />
         <div className="player_name" onClick={editProfile}>
            {state.Jugador.Nombre}
         </div>
         <Button variant="game-danger" onClick={startGame}>
            <span>Nuevo </span>
            <FontAwesomeIcon icon="gamepad" />
         </Button>
      </div>
   );
};

export default StartGame;
