import React from 'react';
import { firebase } from '../api/firebase';
import { fixID } from '../utils'

const useAuth = () => {
  const fixUser = (user) => {
    if (user !== null) {
      user.id = fixID(user.email, user.phoneNumber);
    }
    return user;
  }

  const [state, setState] = React.useState(() => {
    const user = firebase.auth().currentUser

    return { SignedIn: user !== null, user: fixUser(user), Loaded: false }
  });

  function onChange(user) {
    setState({ SignedIn: user !== null, user: fixUser(user), Loaded: true })
  }

  React.useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange)
    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  return state
}

export default useAuth;