import React, { useState } from "react";
import { Cita } from "../Cita";
import { getCita } from "../../utils";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import Badge from "react-bootstrap/Badge";

const ShowStatus = ({ status, pregunta }) => {
   const badgeVariant = status === "Correcto" ? "success" : "danger";
   const [show, setShow] = useState(false);
   const [textoCita, setTextoCita] = useState("");

   const handleShow = (e) => {
      setShow(true);
   };

   const handleClose = (e) => {
      setShow(false);
   };

   const CitaLink = ({ onClick }) => {
      let cita = getCita(pregunta);

      if (cita !== "" && status !== "") {
         return (
            <h5 className="cita-link" onClick={onClick}>
               <i>{cita}</i>&nbsp;
               <FontAwesomeIcon
                  className="mr-1"
                  icon={faBook}
                  mask={["far"]}
               ></FontAwesomeIcon>
            </h5>
         );
      } else {
         return "";
      }
   };

   React.useEffect(() => {
      console.log (pregunta);
      if (pregunta.query !== "") {
         const query = pregunta.query;
         console.log('api-key', process.env.REACT_APP_BIBLIA_API_KEY)
         console.log('query', query)
         axios({
            method: "GET",
            //url: `https://api.scripture.api.bible/v1/bibles/592420522e16049f-01/search?query=${query}&sort=canonical`,
            url: `https://api.biblia.com/v1/bible/content/RVR60.txt.js?passage=${query}&key=${process.env.REACT_APP_BIBLIA_API_KEY}`,
            headers: {
               "accept": "*/*",
            }
         }).then((result) => {
            
            console.log(result);
            let content = "";
            try {
               content = result.data.text; //data.passages[0].content;
               if (pregunta.query === query) { 
                  setTextoCita(content);
               }
            } catch (err) {
               console.log(err.message);
            }
         });
      }
   }, [pregunta]);

   
   return (
      <div>
         <Badge variant={badgeVariant} className="text-big my-2">
            {status}
         </Badge>
         <CitaLink onClick={handleShow} />
         <Cita
            cita={getCita(pregunta)}
            show={show}
            texto={textoCita}
            onClose={handleClose}
         />
      </div>
   );
};

export default ShowStatus;
