import React,{useContext, useCallback} from 'react'
//import './LoginForm.scss'
import {auth} from '../../api/firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import {db, actions} from '../../store';

import './SigninProviders.scss';

const SigninProviders = ()=>
{
    const {state, dispatch}= useContext(db);
    
    const cb =useCallback((authResult, redirectUrl)=>
    {   
        let nombre = authResult?.additionalUserInfo?.profile?.name;
        //let id = fixID(authResult?.user?.email, authResult?.user?.phoneNumber);

        dispatch({
            type: actions.StartGame,
            newGame: {
               player: authResult.user.uid,
               prevQuestions: [],
               points: 0,
               correctas: 0,
               contestadas: 0
            }
         });
         
    },[]);

    const uiConfig = ()=>{
       console.log(auth.GoogleAuthProvider.PROVIDER_ID)
        return {
            signInFlow:"popup",
            signInOptions: [
                auth.GoogleAuthProvider.PROVIDER_ID,
                
                auth.FacebookAuthProvider.PROVIDER_ID,
                //auth.TwitterAuthProvider.PROVIDER_ID,
                //auth.GithubAuthProvider.PROVIDER_ID,
                // auth.EmailAuthProvider.PROVIDER_ID,
                auth.PhoneAuthProvider.PROVIDER_ID
            ],
            callbacks:{
                signInSuccessWithAuthResult:cb
            }
        };
    }
    return(<StyledFirebaseAuth uiConfig={uiConfig()} firebaseAuth={auth()} />);
}

export default SigninProviders;