import React, { useReducer, useEffect } from "react";
import { StoreReducer, data, db, actions } from "./store";
import UIfx from "uifx";
//Estamos usando varios componentes de firebase,
//asi que la db se llama ahora firebaseDb
import { firebaseDb } from "./api/firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import TopTen from "./components/TopTen/TopTen";
import { ShekinahGame } from "./components/Game";
import ls from "local-storage";
import { EndGame } from "./components/EndGame";
import { Header } from "./components/Header";
import ReactLoading from "react-loading";
import { LoginForm } from "./components/Login";
import { useAuth } from "./hooks";
import { Player } from "./components/Player";

//Media
import rightAudio from "./sounds/right.mp3";
import buzzAudio from "./sounds/wrong.mp3";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
   faVolumeUp,
   faVolumeMute,
   faCheckCircle,
   faTimesCircle,
   faGamepad,
   faTrophy
} from "@fortawesome/free-solid-svg-icons";

//Styles
import "./App.css";
import "./firebaseui-styling.global.css";

function App() {
   library.add(
      fas,
      faVolumeUp,
      faVolumeMute,
      faCheckCircle,
      faTimesCircle,
      faGamepad,
      faTrophy
   );
   const [state, dispatch] = useReducer(StoreReducer, data);
   const { user } = useAuth();

   const bell = new UIfx(rightAudio, {
      volume: 0.4, // number between 0.0 ~ 1.0
      throttleMs: 100
   });

   const buzz = new UIfx(buzzAudio, {
      volume: 0.3, // number between 0.0 ~ 1.0
      throttleMs: 100
   });

   // ANCHOR: Termina el juego cuando lleguemos a 15 preguntas
   useEffect(() => {
      //Si estamos jugando solamente
      if (state.CurrentView === "Game") {
         if (state.currentGame.prevQuestions.length === state.matchQuestions) {
            dispatch({
               type: actions.SetView,
               payload: { CurrentView: "EndGame", PreviousView: "Game" }
            });
         }
      }
   }, [state.currentGame.prevQuestions]);

   // ANCHOR: Cuando cambie el usuario, do
   useEffect(() => {
      if (user !== null) {
         firebaseDb.ref(`/Jugadores/${user.uid}`).on("value", (snapshot) => {
            const player = snapshot.val();
            if (player !== null) {
               // ANCHOR: Cargamos el perfil del usuario
               let comp = player["Perfil"];
               if (typeof comp !== "undefined" && comp !== null) {
                  comp = { ...comp, uid: user.uid };
                  dispatch({ type: actions.SetJugador, Jugador: comp });
               } else {
                  //Perfil vacio, hay que crearlo
                  dispatch({
                     type: actions.SetView,
                     payload: {
                        CurrentView: "Profile",
                        PreviousView: "Game"
                     }
                  });
               }

               // ANCHOR: Cargamos la configuración del usuario
               const settings = player["Settings"];
               if (settings) {
                  dispatch({
                     type: actions.SetSettings,
                     payload: { settings }
                  });
               }
            }
         });
      } else {
         dispatch({
            type: actions.SetJugador,
            Jugador: { uid: 0, Nombre: "", Apodo: "" }
         });
      }

      let playing = false;

      // Si existe un usuario,
      // revisa el local storage para ver si existe un juego en curso
      if (user !== null) {
         playing = ls.get("playing");
      }

      /*  NOTE: Si el usuario no está jugando actualmente ni en el local storage,
       y playing en el local storage tiene un valor, procedemos a cargar el juego
       desde el local storage */
      if (state.playing !== playing && playing) {
         // Obtenemos el jueego del local storage
         let game = ls.get("Game");

         /* Si no existe un juego en el local storage,
         o el juego guardado en el mismo no es del jugador actual,
         creamos uno */
         if (game === null || game.player !== user.uid) {
            game = {
               player: user.uid,
               prevQuestions: [],
               points: 0,
               correctas: 0,
               contestadas: 0
            };
         }

         // Iniciamos un nuevo juego despachando la información del mismo
         dispatch({
            type: actions.StartGame,
            newGame: game
         });
      }
   }, [user]);

   // Cuando cambie la vista al TopTen, cargamos los datos de los usuarios del mismo
   useEffect(() => {
      if (state.CurrentView === "TopTen") {
         const QEvents = (snapshot) => {
            const leaders = [];
            snapshot.forEach(function (data) {
               leaders.unshift(data.val());
            });

            dispatch({
               type: actions.SetTopTen,
               payload: {
                  leaders: leaders,
                  Loading: false
               }
            });
         };

         firebaseDb
            .ref("TopTen")
            .orderByChild("total")
            .limitToLast(state.topEnd)
            .on("value", QEvents);
      }
   }, [state.CurrentView]);

   useEffect(() => {}, [user]);

   return state.Loading ? (
      <ReactLoading
         type="spinningBubbles"
         color="#0f5ab6"
         height={"15%"}
         width={"15%"}
         className="loading-animation"
      />
   ) : (
      <db.Provider value={{ state, dispatch }}>
         <div id="top-container" className="flex-container container mt-sm-0">
            <div className="">
               <Header />

               <div className="mb-4 mx-0 text-center">
                  <div className="game-card animated fadeIn delay-2s">
                     <div className="game-card-body">
                        <div className="App">
                           {state.CurrentView === "TopTen" && <TopTen />}
                           {state.CurrentView === "Game" && (
                              <ShekinahGame bell={bell} buzz={buzz} />
                           )}
                           {state.CurrentView === "EndGame" && <EndGame />}
                           {state.CurrentView === "Login" && <LoginForm />}
                           {state.CurrentView === "Profile" && <Player />}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </db.Provider>
   );
}

export default App;
