import React from "react";
import "animate.css";
import "./header.css";

const Header = () => {
   return (
      <div className="text-center py-2">
         <div className="main-title animated zoomInRight delay-2s ">
            <p className="app-title">Shekinah</p>
            <p className="slogan">¡Aprende animadamente!</p>
         </div>
      </div>
   );
};

export default Header;
