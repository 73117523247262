import fixEmail from './FixEmail'

const fixID = (email, phone) => { 
    if (email !== null ) { 
        return fixEmail(email)
    } 

    return phone;

}

export default fixID;