import React from "react";
import "./TopTen.css";
import LeaderBoard from "./LeaderBoard";
import { StartGame } from "../StartGame";

let start = 0;
let end = 10;

const TopTen = () => {
   return (
      <div className="top-ten">
         <LeaderBoard start={start} end={end} />

         <StartGame />
      </div>
   );
};

export default TopTen;
