import React, { useContext, Fragment, useCallback } from "react";
import { db, actions } from "../../store";
import "./ShekinahGame.css";
import {DoLogout} from '../../api/firebase';

const GameStats = () => {
   const { state, dispatch } = useContext(db);

   const currentQ = state.currentGame.prevQuestions.length + 1;
   const progress = (currentQ / state.matchQuestions) * 100;

   const requestLogout = useCallback(() => {
      DoLogout();
      dispatch({ type: actions.SetView, payload:{CurrentView: "TopTen", PreviousView: "TopTen"}});
   }, []);

   const editProfile = () => { 
      dispatch( { type: actions.SetView, payload:{CurrentView: "Profile", PreviousView: "Game"}});
   }


   return (
      <Fragment>
         <div className="game-stats">
            <div className="progress">
               <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${progress}%` }}
               >
                  <span className="progress__span">
                     {currentQ} / {state.matchQuestions}
                  </span>
               </div>
            </div>
            <div className="player" onClick={editProfile}>{state.Jugador.Apodo}</div>
            <div className="points">
               Puntos:&nbsp;{state.currentGame.points}
            </div>
         </div>
      </Fragment>
   );
};

export default GameStats;
