import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { db } from "../../store";
import LeaderBoard from "./LeaderBoard";

const Top30 = ({ show, onClose }) => {
   const { state } = useContext(db);

   return (
      <Modal show={show} onHide={onClose}>
         <Modal.Header closeButton></Modal.Header>
         <Modal.Body>
            <LeaderBoard start={state.topStart} end={state.topEnd} />
         </Modal.Body>
         <Modal.Footer>
            <Button variant="game-primary" onClick={onClose}>
               Cerrar
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

export default Top30;
