import React, { useContext, Fragment, useMemo } from "react";
import { db, actions } from "../../store";
import { firebaseDb } from "../../api/firebase";
import "./EndGame.css";
import Share from "../StartGame/Share.js";

const EndGame = () => {
   const { state, dispatch } = useContext(db);

   const saveGame = () => {
      //Get Previous points for the user
      firebaseDb
         .ref("TopTen")
         .orderByChild("player")
         .equalTo(`${state.currentGame.player}`)
         .once("value")
         .then((snapshot) => {
            let q = snapshot.val();
            //Si el jugador tiene puntos anteriores, agregarlos al total de esta ronda
            let userPoints =
               q === null ? 0 : Object.keys(q).map((k) => q[k])[0].total;

            let totalPoints = userPoints + state.currentGame.points;
            //Preparar el record del jugador
            let playerRecord = {
               player: state.currentGame.player,
               total: totalPoints,
               apodo: state.Jugador.Apodo
            };

            console.log("Puntos totales:" + totalPoints);
            firebaseDb
               .ref("TopTen")
               .child(`${state.currentGame.player}`)
               .update(playerRecord, (error) => {
                  if (error) {
                     console.log("Data could not be saved." + error);
                  } else {
                     console.log("Data saved successfully.");
                  }
               });

            firebaseDb
               .ref(`/TopTen/${state.currentGame.player}/Partidas`)
               .push({
                  fecha: new Date().toString(),
                  puntos: state.currentGame.points,
                  correctas: state.currentGame.correctas,
                  contestadas: state.currentGame.contestadas
               });

            endGame();
         });
   };

   const endGame = () => {
      dispatch({ type: actions.EndGame, payload: true });
   };

   const message = () => {
      if (state.Jugador.Apodo === "") {
         return "Has finalizado la ronda, estas son tus estadísticas:";
      } else {
         return (
            state.Jugador.Apodo +
            ", has finalizado la ronda, estas son tus estadísticas:"
         );
      }
   };

   function highestScore() {
      let highestScore = 0;

      for (let key in state.userMatchs) {
         if (state.userMatchs.hasOwnProperty(key)) {
            let puntos = state.userMatchs[key].puntos;
            if (puntos > highestScore) {
               highestScore = puntos;
            }
         }
      }

      if (state.currentGame.points > highestScore) {
         highestScore = state.currentGame.points;
      }

      return highestScore;
   }

   const highestScoreMemo = useMemo(highestScore);

   function percentage() {
      let totalCorrectas = state.currentGame.correctas;
      let totalContestadas = state.currentGame.contestadas;

      for (let key in state.userMatchs) {
         if (state.userMatchs.hasOwnProperty(key)) {
            totalCorrectas += state.userMatchs[key].correctas;
            totalContestadas += state.userMatchs[key].contestadas;
         }
      }

      if (totalContestadas === 0) {
         return 0;
      } else {
         return ((totalCorrectas / totalContestadas) * 100).toFixed();
      }
   }

   const percentageMemo = useMemo(percentage);

   return (
      <Fragment>
         <div className="game_title">
            <h5 className="end-card-title">{message()}</h5>
         </div>
         <div className="end_points">
            <div className="points-block">
               <div className="text-points">{state.currentGame.points}</div>
               <p className="end_points_text">puntos obtenidos</p>
            </div>
            <div className="center-border"></div>
            <div className="estadisticas">
               <div className="stats-header">ACUM.</div>
               <div className="stats-data">
                  {Intl.NumberFormat("en-US").format(
                     state.currentUserSavedPoints + state.currentGame.points
                  )}
               </div>
               <div className="stats-header">MÁX.</div>
               <div className="stats-data">{highestScoreMemo}</div>
               <div className="stats-header">EFE.</div>
               <div className="stats-data">{percentageMemo}%</div>
            </div>
         </div>
         <h5 className="end-card-title">
            ¡Gracias por tu tiempo, te motivamos a continuar aprendiendo sobre
            la Palabra de Dios!
         </h5>
         <div className="end_footer">
            <button
               onClick={saveGame}
               className="btn btn-game-primary end-buttons"
               disabled={state.currentGame.player === ""}
            >
               Grabar
            </button>
            <button
               onClick={endGame}
               className="btn btn-game-primary end-buttons"
            >
               Terminar
            </button>
            <Share className="end-buttons" />
         </div>
      </Fragment>
   );
};

export default EndGame;
