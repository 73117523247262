import TitleCase from "./TitleCase";
import validateEmail from './validateEmail';
import fixEmail from './FixEmail';
import fixID from './fixID';

const accentFold = (inStr) => {
    return inStr.replace(
       /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
       (str, a, c, e, i, n, o, s, u, y, ae) => {
          if (a) return "a";
          if (c) return "c";
          if (e) return "e";
          if (i) return "i";
          if (n) return "n";
          if (o) return "o";
          if (s) return "s";
          if (u) return "u";
          if (y) return "y";
          if (ae) return "ae";
       }
    );
 };
 
 const getVersos = (pregunta) => {
    let versos = `${pregunta.VERSODESDE}`;
    if (pregunta.VERSOHASTA !== pregunta.VERSODESDE) {
       versos = `${versos}-${pregunta.VERSOHASTA}`;
    }
    return versos;
 };

 const getCita = (pregunta) => {
    let cita = "";
    if (pregunta !== null) {
       cita = `${pregunta.Libro} ${pregunta.CAP}:${getVersos(pregunta)}`;
    }
    return cita;
 };

export {TitleCase, validateEmail, fixEmail, fixID, accentFold, getVersos, getCita}