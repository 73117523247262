const data = {
   currentGame: {},
   Loading: true,
   leaders: [],
   playing: false,
   cancelGame: false,
   CurrentView: "TopTen",
   PreviousView: "TopTen",
   currentUserSavedPoints: 0,
   matchQuestions: 15,
   topStart: 10,
   topEnd: 40,
   CurrentUser: {},
   Jugador: { uid: "", Nombre: "", Apodo: ""},
   SignedIn: false,
   SignedInUser: {},
   userSettings: {
      sound: true
   }
};
export default data;
