import React,{useState, useContext} from 'react'
import './LoginForm.scss'
import {fAuth, DoSendEmailVerification} from '../../api/firebase';
import {useForm} from '../../hooks'
import {validateEmail} from  '../../utils'
import {SigninProviders} from '../SignInProviders';
import {db, actions} from '../../store';
import TextField from '@material-ui/core/TextField';


const LoginForm = ()=>
{
    const { dispatch} = useContext(db);
    const [usernameErr, setUsernameErr] = useState('')
    const [pwdErr, setPwdErr] = useState('')
    const [fields, setFields,reset, isNew] = useForm({username:'', password:'', confirmPassword:'', isNew:false})

    const newAccount = ()=>
    {
        isNew(!fields.isNew);
    }

    const login = ()=>
    {
        if(validateEmail(fields.username) && fields.password!=="")
        {
            fAuth.signInWithEmailAndPassword(fields.username,fields.password).then(r=>{
                setPwdErr("")
                setUsernameErr("")
            }).catch(err=>{
                
                setPwdErr(err.message);
            });
        }
        else if(!validateEmail(fields.username)){
            setUsernameErr("not a valid email")
            setPwdErr("")
        }
        else if(fields.password==="")
        {
            setPwdErr("enter a password")
            setUsernameErr("")
        }
    }

    const create = ()=>
    {
        if(fields.password === fields.confirmPassword)
        {
            fAuth.createUserWithEmailAndPassword(fields.username,fields.password).then(r=>{
                DoSendEmailVerification();
                
                let nombre = fields.username;
                
                //let id = fixEmail(r.user?.email);
                dispatch({type:actions.SetJugador, Jugador: {uid: r.user.uid, Nombre: nombre, Apodo: nombre}});
                dispatch({
                    type: actions.StartGame,
                    newGame: {
                    player: r.user.uid,
                    prevQuestions: [],
                    points: 0,
                    correctas: 0,
                    contestadas: 0
                    }
                });
            });
        }
    }

    return(
        <div className="login">
            <div className="LoginForm">
                <h3 className="BasicInfoLabel">Login</h3>
                <hr />
                
                <TextField type="email" id="username" aria-describedby="emailHelp" name="username" label="Email" value={fields.username} onChange={setFields} variant="outlined" />
                <div className="loginerror" style={{color:'red'}}>{usernameErr}</div>
                
                <TextField label="Clave" type="password" id="password" placeholder="Password"name="password" value={fields.password} onChange={setFields} variant="outlined" />
                <div className="loginerror" style={{color:'red'}}>{pwdErr}</div>
                
                {fields.isNew &&(
                    <TextField label="Confirmar Clave" type="password" id="pass2" placeholder="Password" name="confirmPassword" value={fields.confirmPassword} onChange={setFields} variant="outlined"/>
                )}

                <div className="Loginbtns">
                    {!fields.isNew && <span className="new-account" onClick={newAccount}>Crear Cuenta</span>}
                    <button className="btn btn-game-primary btn-new-account" onClick={!fields.isNew?login:create}>{!fields.isNew?"Login":"Crear"}</button>
                </div>
            </div>

            <div className="loginOr">
                <hr />
                <span className="spanOr">O</span>
                <hr />
            </div>
            <SigninProviders />
        </div>
    );
}

export default LoginForm;