import React, { useMemo, useContext, useCallback, useState, useEffect } from 'react'
import { useForm, useAuth  } from '../../hooks'
import {db, actions} from '../../store'
import { firebase, DoSendEmailVerification } from '../../api/firebase'
import TextField from '@material-ui/core/TextField';
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import {DoLogout } from "../../api/firebase";

import './Player.scss';

const Player = () => {
    const { state, dispatch } = useContext(db);
    const { user } = useAuth();
    const [apodoErr, setApodoErr] = useState('')
    const [ emailMsg, SetEmailMsg] = useState('')
    const [cFlds, setCFlds, resetCFlds] = useForm(state.Jugador)

    const resendVerification= () => { 
        DoSendEmailVerification();
    }

    const SaveInformation = () => {
        cFlds.CreatedAt = new Date().toString();

        firebase.database().ref('/Apodos').child(cFlds.Apodo.toLowerCase()).set(user.uid, function(err) {

            if( err ) { 
                setApodoErr("Apodo ya está tomado.  Por favor, seleccione otro")
            } else {
                firebase.database().ref(`/Jugadores/${user.uid}/Perfil`).set(cFlds);

                dispatch({ type: actions.SetView, payload:{CurrentView: state.PreviousView, PreviousView: "TopTen"}});
            }
        });
    }

    const doCancel = () => { 
        dispatch({ type: actions.SetView, payload:{CurrentView: state.PreviousView, PreviousView: "TopTen"}});
    }

    const requestLogout = useCallback(() => { 
        DoLogout();
        dispatch({ type: actions.SetJugador, Jugador: {uid: "", Nombre:"", Apodo: ""} });
        dispatch({ type: actions.SetView, payload:{CurrentView: "TopTen", PreviousView: "TopTen"}});
    },[]);

    useMemo(() => {
        resetCFlds(state.Jugador);
    }, [state.Jugador]);

    useEffect(() => { 
        if (user !== null) { 
            if (!user.emailVerified && user.providerData[0].providerId === "password") { 
                SetEmailMsg("Correo Electrónico no ha sido validado.  Favor revisar su buzón o el Correo no Deseado");
            }
        }
    })

    return (
        <div className="Jugador">
            <div className="PlayerInfo">
                <div className="BasicInfoLabel">Información</div>
                <hr />
                <div className="PlayerForm">
                    <TextField name="Nombre" onChange={setCFlds} value={cFlds.Nombre}
                        required id="Nombre" label="Nombre" variant="outlined" />

                    <TextField name="Apodo" onChange={setCFlds} value={cFlds.Apodo}
                        id="Apodo" label="Apodo" variant="outlined" />
                    <div className="apodoerror" style={{color:'red'}}>{apodoErr}</div>
                    {emailMsg !== "" && ( 
                        <div className="eMailMsg">
                            <span  style={{color:"red"}}>{emailMsg}</span>
                            <span onClick={resendVerification}>Re-enviar Correo</span>
                        </div>
                    )}
                    <div className="Actions">
                        <Button
                            variant="game-primary"
                            onClick={SaveInformation}
                        >
                            <span>Grabar </span>
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                        <Button
                            variant="game-danger"
                            className="CancelButton"
                            onClick={doCancel}>
                            Cancelar
                        </Button>
                        <Button 
                            variant="secondary"
                            className="LogoutButton"
                            onClick={requestLogout}
                            >
                            <span>Cerrar Sesión</span> 
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </Button>
                    </div>
                </div>
            </div>
        </div >)
}
export default Player