import React, { Fragment } from 'react';
//import { db } from "../../store"
import "./ShekinahGame.css"

const ShowPregunta = ({pregunta}) => { 
    //const { state } = useContext(db);

    const estilo = () => { 
        let retval = "e";  //easy
        if (pregunta.Dificultad === "Avanzado") { 
            retval = "h";
        } else if (pregunta.Dificultad === "Medio") { 
            retval = "m";
        } 

        return retval;
    }

    const borderClass = () => {
        let estilos = {"e": "badge badge-success", "h":"badge badge-danger", "m":"badge badge-warning"};
        return estilos[estilo()];
    } 


    return (
        <Fragment> 
            <div className="add_title">
                <h4 className="game-card-title pregunta">
                    {pregunta.Pregunta}
                    <small><span className={borderClass()}>{pregunta.Dificultad}</span></small>
                </h4>
            </div>
        </Fragment>
    )
}

export default ShowPregunta;