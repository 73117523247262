import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import Sharing from "../Sharing/Sharing";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";

const Share = (props) => {
   /* const [sharing, setSharing] = useState(false);
   const shareGame = () => {
      setSharing(!sharing);
   }; */

   const popover = (
      <Popover id="popover-basic">
         <Popover.Title as="h3">Compartir</Popover.Title>
         <Popover.Content>
            <Sharing />
         </Popover.Content>
      </Popover>
   );

   return (
      <OverlayTrigger
         trigger="click"
         placement="auto"
         overlay={popover}
         rootClose="true"
      >
         <Button variant="game-primary" className={props.className || ""}>
            <span>{props.title}</span>
            <FontAwesomeIcon icon={faShareAlt} />
         </Button>
         {/*   */}
      </OverlayTrigger>
   );
};

export default Share;
/*        {sharing && <Sharing />} */
