import React, { useContext } from "react";
import { db } from "../../store";
import Table from "react-bootstrap/Table";
import "animate.css";
import "./TopTen.css";

const LeaderBoard = ({ start, end }) => {
   const { state } = useContext(db);

   if (process.env.REACT_APP_FIREBASE_PROJECTID === "shekinah-staging") {
      for (let x = 1; x < 100; x++) {
         state.leaders.push({
            player: "Juan Pedro Cabral Monegro",
            total: 400000,
            apodo: `Juan${x}`
         });
      }
   }

   let top = start + end;

   return (
      <div className="">
         <div className="top_title text-primary">
            <h3 className="leader-board__title">Top {top}</h3>
         </div>
         <div className="leaderboard">
            <div className="">
               <Table
                  id="leaders"
                  className="h4 text-weight-bold leaders-table table-sm"
                  hover>
                  <tbody className="">
                     {state.leaders
                        .slice(start, start + end)
                        .map((leader, index) => {
                           return (
                              <tr key={index} className="text-left">
                                 <td className="leaders-table__players">
                                    {(start + index + 1).toString() + ". "}
                                    {leader.apodo}
                                 </td>
                                 <td className="text-right leaders-table__total">
                                    {Intl.NumberFormat("en-US").format(
                                       leader.total
                                    )}
                                 </td>
                              </tr>
                           );
                        })}
                  </tbody>
               </Table>
            </div>
         </div>
      </div>
   );
};

export default LeaderBoard;
