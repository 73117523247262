import React from "react";
import {
   FacebookShareButton,
   FacebookMessengerShareButton,
   FacebookMessengerIcon,
   TwitterShareButton,
   WhatsappShareButton,
   EmailShareButton,
   FacebookIcon,
   TwitterIcon,
   WhatsappIcon,
   EmailIcon
} from "react-share";

const Sharing = () => {
   const shareUrl = window.location.origin
      ? window.location.origin + "/"
      : window.location.protocol + "/" + window.location.host + "/";
   const title = "Te invito a aprender sobre la Biblia jugando Shekinah!";

   return (
      <div className="share__cont">
         <div className="share__some-network">
            <FacebookShareButton
               url={shareUrl}
               quote={title}
               className="share__some-network__share-button"
            >
               <FacebookIcon size={64} round />
            </FacebookShareButton>
         </div>
         <div className="share__some-network">
            <FacebookMessengerShareButton
               url={shareUrl}
               appId="521270401588372"
               className="share__some-network__share-button"
            >
               <FacebookMessengerIcon size={64} round />
            </FacebookMessengerShareButton>
         </div>
         <div className="share__some-network">
            <TwitterShareButton
               url={shareUrl}
               title={title}
               className="share__some-network__share-button"
            >
               <TwitterIcon size={64} round />
            </TwitterShareButton>
         </div>
         <div className="share__some-network">
            <WhatsappShareButton
               url={shareUrl}
               title={title}
               separator=":: "
               className="share__some-network__share-button"
            >
               <WhatsappIcon size={64} round />
            </WhatsappShareButton>
         </div>
         <div className="share__some-network">
            <EmailShareButton
               url={shareUrl}
               subject={title}
               body="body"
               className="share__some-network__share-button"
            >
               <EmailIcon size={64} round />
            </EmailShareButton>
         </div>
      </div>
   );
};

export default Sharing;
